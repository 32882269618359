import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { IPasswordLoginFormInfo, IPasswordLoginProps, IPasswordLoginRef } from './types';
import { validateEmail, validatePassword } from '../../../../utils/validate';
import PasswordTextField from '@/components/passwordTextField';

const PasswordLogin: ForwardRefRenderFunction<IPasswordLoginRef, IPasswordLoginProps> = (props, ref) => {
  const { register, formState: { errors }, handleSubmit } = useForm<IPasswordLoginFormInfo>();
  useImperativeHandle(ref, () => ({
    submit: handleSubmit(props.onSubmit),
  }));
  return (
    <>
      <TextField
        fullWidth
        size='small'
        margin='normal'
        label='Email'
        type='email'
        {...register('userName', { validate: validateEmail })}
        error={Boolean(errors.userName)}
        helperText={
          errors.userName ? errors.userName.message : ' '
        }
      />
      <PasswordTextField
        fullWidth
        size='small'
        margin='normal'
        label='Password'
        type='password'
        {...register('password', { validate: validatePassword })}
        error={Boolean(errors.password)}
        helperText={
          errors.password ? errors.password.message : ' '
        }
      />
    </>
  );
};
export default forwardRef(PasswordLogin);
