import { OPEN_LOGIN_MODAL, TMS_ORGANIZATION_ID_KEY, TMS_TOKEN_KEY } from '@/constants';
import storage from '@/utils/storage';
import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { Env } from '../../config/env';
import { userInfoStore } from '../../models';
import arms from '@/utils/arms';
import PubSub from 'pubsub-js';

const handleTokenDisabled = () => {
  storage.localRemove(TMS_ORGANIZATION_ID_KEY);
  storage.localRemove(TMS_TOKEN_KEY);
  // location.replace('/');
  PubSub.publish(OPEN_LOGIN_MODAL);
};

export const api: AxiosInstance = axios.create({
  baseURL: Env.apiUrl,
  timeout: 1000 * 60 * 10, // 10min
});

/* 请求拦截器 */
api.interceptors.request.use(
  (config) => {
    if (userInfoStore.token) {
      (config.headers as AxiosRequestHeaders).Authorization = userInfoStore.token;
    }
    if (userInfoStore.organizationId) {
      (config.headers as AxiosRequestHeaders).OrganizationId = userInfoStore.organizationId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
/* 响应拦截器 */
api.interceptors.response.use(
  (response) => {
    if (response.data.Code && response.data.Code !== 20000) {
      return Promise.reject(new Error(response.data.Message));
    }
    // return response.data.Data !== undefined && response.data.Data !== null ? response.data : { Data: response.data };
    return response.data;
  },
  (error) => {
    // if (error.response && error.response.data) {
    //   return Promise.reject(new Error(error.response.data));
    // }
    if (error.response?.status === 401) {
      handleTokenDisabled();
    }
    arms.error(error);
    return Promise.reject(error);
  },
);
