import { SxProps } from '@mui/material';

export const loginPage: SxProps = {
  width: 1,
  height: '100%',
  bgcolor: 'background.default',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};
export const loginContent: SxProps = {
  width: '1000px',
  height: '600px',
  display: 'flex',
  justifyContent: 'space-between',
};
export const loginContentLeft: SxProps = {
  width: '50%',
  bgcolor: 'primary.main',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export const loginContentRight: SxProps = {
  width: '50%',
  paddingTop: '100px',
};
export const logoBox: SxProps = {
  marginX: 'auto',
  marginBottom: '40px',
  width: '200px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
};
export const loginForm: SxProps = {
  width: '80%',
  marginX: 'auto',
};
