import { IOrganizationSelectorRef } from './components/organizationSelector/types';
import { ILoginInfo } from '@/services/api/user.types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userInfoStore } from '../../models';
import { passwordlogin, verificationCodeLogin } from '../../services/api/user';
import { postLog } from '@/services/api/log';

const useAction = () => {
  const [errorMsg, setErrorMsg] = useState('');
  const [loginType, setLoginType] = useState<'password' | 'verificationCode'>('password');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const organizationSelectorRef = useRef<IOrganizationSelectorRef>(null);
  const [openModal, setOpenModal] = useState(false);
  const changeLoginType = () => {
    if (loginType === 'password') {
      setLoginType('verificationCode');
    } else {
      setLoginType('password');
    }
  };
  const [loginInfo, setLoginInfo] = useState<ILoginInfo | null>(null);
  const handleLoginCallback = (data: ILoginInfo) => {
    if (data.AccessToken) {
      userInfoStore.setOrganizations(data.Organizations);
      setLoginInfo(data);
      setLoading(false);
      if (data.Organizations.length > 1) {
        organizationSelectorRef.current?.open();
        return;
      }
      if (data.Organizations.length === 1) {
        userInfoStore.setOrganization(data.Organizations[0].Id);
        userInfoStore.setToken(data.AccessToken);
        userInfoStore.updateUserInfo(data);
        postLog();
      }
      if (openModal) {
        setOpenModal(false);
        location.reload();
      } else {
        navigate('/', { replace: true });
      }
    }
  };
  const saveLoginInfo = () => {
    if (loginInfo) {
      userInfoStore.setToken(loginInfo.AccessToken);
      userInfoStore.updateUserInfo(loginInfo);
      postLog();
    }
  };
  const loginByVerificationCode = async (data: {
    phoneNumber: string;
    verificationCode: string;
  }) => {
    try {
      setErrorMsg('');
      setLoading(true);
      const { Data } = await verificationCodeLogin({
        code: data.verificationCode,
        phoneNumber: data.phoneNumber,
      });
      handleLoginCallback(Data);
    } catch (error) {
      setErrorMsg((error as Error).message);
      setLoading(false);
    }
  };
  const loginByPassword = async (data: { userName: string; password: string }) => {
    try {
      setErrorMsg('');
      setLoading(true);
      const { Data } = await passwordlogin({
        email: data.userName,
        password: data.password,
      });
      handleLoginCallback(Data);
    } catch (error) {
      setErrorMsg((error as Error).message);
      setLoading(false);
    }
  };
  return {
    errorMsg,
    loading,
    loginType,
    organizationSelectorRef,
    openModal,
    setOpenModal,
    saveLoginInfo,
    navigate,
    changeLoginType,
    loginByPassword,
    loginByVerificationCode,
  };
};
export default useAction;
