import { Alert, Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from 'react';
import { loginForm } from './styles';
import { LoadingButton } from '@mui/lab';
import { IPasswordLoginRef } from './components/passwordLogin/types';
import { IVerificationCodeLoginRef } from './components/verificationCodeLogin/types';
import useAction from './hooks';
import PasswordLogin from './components/passwordLogin';
import VerificationCodeLogin from './components/verificationCodeLogin';
import OrganizationSelector from './components/organizationSelector';

export interface ILoginModalRef {
  open: () => void;
}
const LoginModal: ForwardRefRenderFunction<ILoginModalRef, {}> = (props, ref) => {
  const {
    errorMsg, loading, loginType, organizationSelectorRef, openModal, setOpenModal,
    saveLoginInfo, changeLoginType, loginByPassword, loginByVerificationCode,
  } = useAction();
  const passwordLoginFormRef = useRef<IPasswordLoginRef>(null);
  const verificationCodeLoginFormRef = useRef<IVerificationCodeLoginRef>(null);
  useImperativeHandle(ref, () => ({
    open: () => {
      setOpenModal(true);
    },
  }));
  const handleSubmit = () => {
    if (loginType === 'verificationCode') {
      verificationCodeLoginFormRef.current && verificationCodeLoginFormRef.current.submit();
    } else {
      passwordLoginFormRef.current && passwordLoginFormRef.current.submit();
    }
  };
  const handleSelectedOrganization = () => {
    saveLoginInfo();
    setOpenModal(false);
    location.reload();
  };

  const handleClose = () => {
    setOpenModal(false);
    location.replace('/');
  };
  return (
    <>
      <Dialog open={openModal} fullWidth>
        <DialogContent>
          <Box sx={loginForm}>
            <Typography
              variant='h6'
              component='p'
              gutterBottom
              sx={{ textTransform: 'capitalize' }}
            >
              sign in
            </Typography>
            {loginType === 'password' ? (
              <PasswordLogin ref={passwordLoginFormRef} onSubmit={loginByPassword}/>
            ) : (
              <VerificationCodeLogin ref={verificationCodeLoginFormRef} onSubmit={loginByVerificationCode} />
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant='text'
                onClick={changeLoginType}
                size='small'
                disableRipple
                sx={{ textTransform: 'capitalize' }}
              >
                {loginType === 'password' ? 'SMS verification code login' : 'Password login'}
              </Button>
            </Box>
            <LoadingButton fullWidth variant='contained' onClick={handleSubmit} loading={loading}>
              sign in
            </LoadingButton>
            {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color='warning' onClick={handleClose}>退出系統</Button>
        </DialogActions>
      </Dialog>
      <OrganizationSelector ref={organizationSelectorRef} onOK={handleSelectedOrganization}/>
    </>
  );
};
export default forwardRef(LoginModal);
