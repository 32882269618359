import React, { FC, useRef, useState } from 'react';
import { Box, Button, FormHelperText } from '@mui/material';
import { getVerificationCode } from '../../../../services/api/user';
import { validatePhoneNumber } from '../../../../utils/validate';

interface IVerificationCodeBtnProps {
  phoneNumber: string
  restTime: number
  onSended: () => void
  onSendError: (error: string) => any
}
const useAction = (props: IVerificationCodeBtnProps) => {
  const { phoneNumber, onSendError, onSended } = props;
  const [restTime, setRestTime] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const msgTimer = useRef<unknown>();
  const handleRestTime = () => {
    let newRestTime = props.restTime;
    setRestTime(newRestTime);
    const timer = setInterval(() => {
      newRestTime = newRestTime - 1;
      setRestTime(newRestTime);
      if (newRestTime === 0) {
        clearInterval(timer);
      }
    }, 1000);
    return timer;
  };
  const handleErrorMsg = (errorMsg: string) => {
    setErrorMsg(errorMsg);
    msgTimer.current = setInterval(() => {
      setErrorMsg('');
    }, 6000);
  };
  const handleClick = () => {
    setErrorMsg('');
    if (typeof msgTimer.current === 'number') {
      clearInterval(msgTimer.current);
    }
    const error = validatePhoneNumber(phoneNumber);
    if (error) {
      onSendError(error);
      return;
    }
    const timer = handleRestTime();
    getVerificationCode({
      region: '1',
      phoneNumber,
      type: 0,
    }).catch((error) => {
      clearInterval(timer);
      setRestTime(0);
      handleErrorMsg(error.message);
    });
    onSended();
  };
  return {
    restTime,
    errorMsg,
    handleClick,
  };
};

const VerificationCodeBtn: FC<IVerificationCodeBtnProps> = (props) => {
  const { errorMsg, restTime, handleClick } = useAction(props);
  return (
    <>
      <Box sx={{ width: '200px' }}>
        <Button fullWidth onClick={handleClick} disabled={restTime > 0} sx={{ width: '200px', height: '37px', marginTop: 2, boxShadow: 'none', textTransform: 'capitalize' }} variant="contained">
          {restTime > 0 ? `${restTime} seconds` : 'Send verification code'}
        </Button>
        {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      </Box>
    </>
  );
};
export default VerificationCodeBtn;
