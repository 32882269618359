import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { IOrganizationSelectorProps, IOrganizationSelectorRef } from './types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { userInfoStore } from '@/models';

const OrganizationSelector: ForwardRefRenderFunction<IOrganizationSelectorRef, IOrganizationSelectorProps> = (props, ref) => {
  const { onOK } = props;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));
  const handleOK = () => {
    if (value) {
      onOK && onOK();
      userInfoStore.setOrganization(value);
      setOpen(false);
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Select Organization</DialogTitle>
      <DialogContent>
      <RadioGroup
        name="select-organization"
        value={value}
        onChange={handleChange}
      >
      {
        userInfoStore.organizations.map((item) => (
          <FormControlLabel key={item.Id} value={item.Id} control={<Radio />} label={item.Name} />
        ))
      }
      </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOK}>確認</Button>
      </DialogActions>
    </Dialog>
  );
};
export default forwardRef(OrganizationSelector);
