import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle } from 'react';
import { InputAdornment, Stack, TextField } from '@mui/material';
import VerificationCodeBtn from './VerificationCodeBtn';
import { IVerificationCodeLoginFormInfo, IVerificationCodeLoginProps, IVerificationCodeLoginRef } from './types';
import { useForm } from 'react-hook-form';
import { validatePhoneNumber, validateVerificationCode } from '../../../../utils/validate';

const VerificationCodeLogin: ForwardRefRenderFunction<IVerificationCodeLoginRef, IVerificationCodeLoginProps> = (props, ref) => {
  const { register, formState: { errors }, watch, handleSubmit, trigger } = useForm<IVerificationCodeLoginFormInfo>();
  useImperativeHandle(ref, () => ({
    submit: handleSubmit(props.onSubmit),
  }));
  const handleVerificationCode = () => {
    trigger('phoneNumber', { shouldFocus: false });
  };
  const phoneNumber = watch('phoneNumber');
  return (
    <>
      <TextField
        size='small'
        margin='normal'
        fullWidth
        label='Phone Number'
        {...register('phoneNumber', { validate: validatePhoneNumber })}
        error={Boolean(errors.phoneNumber)}
        helperText={errors.phoneNumber ? errors.phoneNumber.message : ' '}
        InputProps={{
          startAdornment: <InputAdornment position="start">+1</InputAdornment>,
        }}
      />
      <Stack direction="row" spacing={0.5} alignItems="flex-start">
        <TextField
          size='small'
          margin='normal'
          label='Verification Code'
          {...register('verificationCode', { validate: validateVerificationCode })}
          error={Boolean(errors.verificationCode)}
          helperText={
            errors.verificationCode ? errors.verificationCode.message : ' '
          }
        />
        <VerificationCodeBtn phoneNumber={phoneNumber} restTime={60} onSendError={handleVerificationCode} onSended={handleVerificationCode}/>
      </Stack>
    </>
  );
};
export default forwardRef(VerificationCodeLogin);
